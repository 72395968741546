<template>
  <div class="patient__History">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div class="col-9 p-0 align-self-center font-weight-bold d-flex align-items-center">
          <h5 class="d-inline mr-2 font-weight-bold">{{$t('message.dapartment_user')}}</h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
        </div>
        <div
          class="col-3 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
        >
          <el-button  size="mini" @click="drawer.create.status = true" icon="el-icon-circle-plus-outline">
              {{$t('message.create')}}
          </el-button>
        </div>
      </div>
      <table class="table table-bordered table-hover" v-loading="loadingData">
            <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
            <thead>
          <tr>
              <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
              <crm-th :column="columns.branch_id" :sort="sort" @c-change="updateSort"></crm-th>
              <crm-th :column="columns.user_id" :sort="sort" @c-change="updateSort"></crm-th>
              <crm-th :column="columns.settings" :sort="sort" @c-change="updateSort"></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              />
            </th>
            <th v-if="columns.branch_id.show">
               <el-select filterable clearable :placeholder="$t('message.branch')" size="mini"
                  v-model="filterForm.branch_id">
                  <el-option v-for="item in branches" :key="item.name + item.id" :label="item.name" :value="item.id"/>
              </el-select>
            </th>
            <th v-if="columns.user_id.show">
                <el-select filterable clearable :placeholder="$t('message.dapartment_user')" size="mini"
                    v-model="filterForm.user_id">
                    <el-option v-for="item in users" :key="item.name + item.id" :label="item.name+ ' '+ item.surname" :value="item.id"/>
                </el-select>
            </th>
            <th v-if="columns.settings.show"/>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="user in list" :key="user.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ user.id }}</td>
            <td v-if="columns.branch_id.show">{{ user.branch? user.branch.name: '' }}</td>
            <td v-if="columns.user_id.show">{{ user.user? (user.user.name +' '+user.user.surname + ' '+ (user.user? user.user.patronymic: '') ): '' }}</td>
            <td v-if="columns.settings.show" class="settings-td">
               <crm-setting-dropdown :model="user" name="purposeHospitalization" :actions="actions" @edit="edit" @delete="destroy"/>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer 
            size="60%" 
            :wrapperClosable="false"
            :visible.sync="drawer.create.status"
            :ref="drawer.create.name"
            @opened="drawerOpened(drawer.create.component)"
            @closed="drawerClosed(drawer.create.component)">
                <crm-create :ref="drawer.create.component" :drawer-name="drawer.create.name"/>
        </el-drawer>

        <el-drawer 
            size="60%" 
            :wrapperClosable="false"
            :visible.sync="drawer.update.status"
            :ref="drawer.update.name"
            @opened="drawerOpened(drawer.update.component)"
            @closed="drawerClosed(drawer.update.component)">
                <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name"/>
        </el-drawer>
    <!-- end modal -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
    import CrmCreate from "./components/crm-create_department_user";
    import CrmUpdate from "./components/crm-update_department_user";
    import list from "@/utils/mixins/list";
    import drawer from "@/utils/mixins/drawer";
    export default {
        mixins:[list,drawer],
        name: "departments",
        components:{
            CrmCreate,
            CrmUpdate
        },
        data(){
            return {
                loadingData: false,
                selectedModel: {},
                drawer: {
                    create: {
                        name: "create",
                        status: false,
                        component: 'componentDrawerCreate'
                    },
                    update: {
                        name: "update",
                        status: false,
                        component: 'componentDrawerUpdate'
                    }
                }
            }
        },
        computed:{
            ...mapGetters({
                list: 'departmentUsers/list',
                columns: "departmentUsers/columns",
                pagination: "departmentUsers/pagination",
                filter: "departmentUsers/filter",
                sort: "departmentUsers/sort",
                users: "users/list",
                branches: "branches/list",
            }),
            actions: function() {
              return ['edit', 'delete'];
            }
        },
        async mounted(){
            if (this.users && this.users.length === 0)
              await  this.loadUsers();
            if (this.branches && this.branches.length === 0)
              await  this.loadBranches();
        },
        methods: {
            ...mapActions({
                updateList: 'departmentUsers/index',
                updateSort: "departmentUsers/updateSort",
                updateFilter: "departmentUsers/updateFilter",
                updateColumn: "departmentUsers/updateColumn",
                updatePagination: "departmentUsers/updatePagination",
                editModel: 'departmentUsers/show',
                empty: 'departmentUsers/empty',
                delete: 'departmentUsers/destroy',
                refreshData: 'departmentUsers/refreshData',
                loadUsers: "users/index",
                loadBranches: "branches/index",
            }),
            fetchData() {
                const query = { ...this.filter, ...this.pagination, ...this.sort };
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },
            refresh() {
                this.refreshData()
                    .then(res => {
                        this.filterForm = JSON.parse( JSON.stringify( this.filter ));
                    })
                    .catch(err => {

                    })
            },
           edit(model){
                this.selectedModel = model;
                this.drawer.update.status = true;
            },
            async show(model){
                await this.showModel(model.id)
                    .then(res => {
                        this.drawerShow = true;
                    })
                    .catch(err => {

                    })
            },
            destroy(model){
                this.delete(model.id)
                    .then(res => {
                        this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                        this.fetchData()
                    })
                    .catch(err => {
                       console.log(err)
                    })
            },
            emptyModel(){
                this.empty()
            },
        }
};
</script>
